import { reactive, computed } from 'vue';
import { nextTick } from 'vue';
import { useTenancy, useUser } from '@/modules/auth';

const chatInitialState = {
  chatChannelName: null,
  chatChannelUrl: null,
  chatBusinessName: null,
  isChannelEnabled: false,
};

const chatState = reactive({
  ...chatInitialState,
});

const useChatModal = function () {
  const { isAdmin } = useUser();
  const { currentTenant } = useTenancy();

  const closeChatModal = () => {
    chatState.chatChannelName = chatInitialState.chatChannelName;
    chatState.chatChannelUrl = chatInitialState.chatChannelUrl;
    chatState.chatBusinessName = chatInitialState.chatBusinessName;
    chatState.isChannelEnabled = chatInitialState.isChannelEnabled;
  };

  const openChatModal = ({ supplier, title, customer, reconciliationId, formattedPeriod, isChannelEnabled }) => {
    closeChatModal();
    nextTick(() => {
      chatState.chatChannelName = {
        title,
        subtitle: `${supplier.name} <> ${customer?.name ?? currentTenant.value.name}`,
        name: `${supplier.name} <> ${customer?.name ?? currentTenant.value.name} <> ${formattedPeriod}`,
      };
      chatState.chatChannelUrl = `reconciliation_${reconciliationId}`;
      chatState.chatBusinessName = isAdmin?.value ? 'Clarity' : currentTenant.value.name;
      chatState.isChannelEnabled = isChannelEnabled;
    });
  };

  const isChatOpen = (channelUrl) => channelUrl === chatState.chatChannelUrl;

  return {
    closeChatModal,
    openChatModal,
    isChatOpen,
    chatChannelName: computed(() => chatState.chatChannelName),
    chatChannelUrl: computed(() => chatState.chatChannelUrl),
    chatBusinessName: computed(() => chatState.chatBusinessName),
    isChannelEnabled: computed(() => chatState.isChannelEnabled),
  };
};

export default useChatModal;
