import { gql } from '@apollo/client/core';

const SENDBIRD_TOKEN_QUERY = gql`
  query getSendbirdToken {
    sendbirdToken
  }
`;

const LIST_USER_CHANNELS_QUERY = gql`
  query listUserChannels($channelUrls: [String!]) {
    chatListUserChannels(channelUrls: $channelUrls) {
      name
      channel_url
      created_at
      unread_message_count
      member_state
      last_message {
        message
        created_at
        message
      }
    }
  }
`;

export { SENDBIRD_TOKEN_QUERY, LIST_USER_CHANNELS_QUERY };
