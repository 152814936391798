<template>
  <div v-loading="!channel" class="chat-container">
    <ChatWindow
      :class="$direction === 'rtl' ? 'rtl-chat' : ''"
      :current-user-id="userId"
      :messages="messages"
      :messages-loaded="messagesLoaded"
      :text-messages="textMessages"
      :rooms-loaded="roomsLoaded"
      :loading-rooms="!roomsLoaded"
      :single-room="true"
      :rooms="rooms"
      :room-id="channelUrl"
      :show-new-messages-divider="false"
      :show-audio="false"
      :show-files="false"
      :show-add-room="false"
      :message-actions="[]"
      :show-reaction-emojis="false"
      :show-footer="isChannelEnabled"
      @send-message="sendMessage"
      @fetch-messages="loadMessages"
      @typing-message="handleTyping"
    >
      <template #room-header>
        <div class="w-100 px-4 d-flex justify-content-between">
          <div class="w-100 text-truncate" :class="$direction === 'ltr' ? 'me-2' : 'ms-2'">
            <div class="w-100 text-truncate" :class="$direction === 'ltr' ? 'me-2' : 'ms-2'">
              <div class="d-flex">
                <div class="fw-bold d-inline-block text-truncate">
                  {{ channelName.title }}
                </div>
              </div>
              <p class="d-inline-block text-truncate">{{ channelName.subtitle }}</p>
            </div>
            <div v-if="typingUsers" class="vac-room-info vac-text-ellipsis">
              {{ typingUsers }}
            </div>
          </div>
          <div class="actions-btn rounded close-icon-container" @click="$emit('chat-close')">
            <CloseIcon />
          </div>
        </div>
      </template>
      <template #message="{ message }">
        <div
          v-if="$sendbird.currentUser"
          class="d-flex w-100 message-row"
          :class="
            (message.senderId !== $sendbird.currentUser.userId) & ($direction === 'rtl') ||
            (message.senderId === $sendbird.currentUser.userId) & ($direction === 'ltr')
              ? 'flex-row-reverse'
              : 'flex-row'
          "
        >
          <div
            class="d-flex my-4"
            :class="
              (message.senderId !== $sendbird.currentUser.userId) & ($direction === 'rtl') ||
              (message.senderId === $sendbird.currentUser.userId) & ($direction === 'ltr')
                ? 'flex-row-reverse'
                : 'flex-row'
            "
          >
            <template v-if="message.senderProfileUrl">
              <img class="mx-2" :src="message.senderProfileUrl" alt="" width="30" height="30" />
            </template>
            <template v-else>
              <div
                class="badge-container d-flex justify-content-center align-items-center mx-2 bg-secondary rounded-pill"
              >
                <h3
                  class="text-uppercase badge-letters d-flex justify-content-center align-items-center mx-2 text-typography-secondary rounded-pill"
                >
                  {{ message.initials }}
                </h3>
              </div>
            </template>
            <div
              v-if="$sendbird.currentUser"
              :class="[
                message.senderId === $sendbird.currentUser.userId
                  ? 'bg-primary text-white'
                  : 'bg-secondary text-typography-primary',
                'chat-card rounded p-2',
              ]"
            >
              <small class="fw-bold">
                {{ message.username }}<template v-if="message.businessName">, {{ message.businessName }}</template>
              </small>
              <p class="py-1">{{ message.content }}</p>
              <small class="opacity-50">{{ message.timestamp }}</small>
            </div>
          </div>
          <div v-if="shouldShowDelete(message, $sendbird.currentUser)" class="m-2 delete-message-button">
            <Button type="text" @click="deleteMessage(message)">
              <div class="text-typography-secondary delete-message-icon"><TrashCanIcon /></div>
            </Button>
          </div>
        </div>
      </template>
    </ChatWindow>
  </div>
</template>

<script>
import { DateTime } from 'luxon';
import { isNil } from 'ramda';
import { computed, ref, watch, getCurrentInstance } from 'vue';
import ChatWindow from 'vue-advanced-chat';
import 'vue-advanced-chat/dist/vue-advanced-chat.css';
import { v4 as uuidv4 } from 'uuid';
import { GroupChannelHandler } from '@sendbird/chat/groupChannel';
import { MessageMetaArray } from '@sendbird/chat/message';

import { CloseIcon, TrashCanIcon } from '@/assets/icons';
import { useUser } from '@/modules/auth';
import { Button } from '@/modules/core';
import { options } from '@/locale/dateConfig';
import i18n from '@/imports/startup/client/i18n';
import { useChannel } from '@/modules/reconciliation';

import { useSendbirdToken } from '../';

const getTypingText = (room, currentUserId, textMessages) => {
  if (room?.typingUsers && room?.typingUsers.length) {
    const typingUsers = room.users.filter((user) => {
      if (user._id === currentUserId) return;
      if (room.typingUsers.indexOf(user._id) === -1) return;
      if (user.status && user.status.state === 'offline') return;
      return true;
    });

    if (!typingUsers.length) return;

    if (room.users.length === 2) {
      return textMessages.IS_TYPING;
    } else {
      return typingUsers.map((user) => user.username).join(', ') + ' ' + textMessages.IS_TYPING;
    }
  }
};

const transformChannelToRoom = (channel, subtitle) => ({
  roomId: channel.url,
  roomName: subtitle,
  users: (channel.members || []).map(transformMemberToUser),
  typingUsers: [],
});

const transformMemberToUser = (member) => ({
  _id: member.userId,
  username: member.nickname,
  avatar: member.profileUrl,
});

const zeroPad = (num, pad) => {
  return String(num).padStart(pad, '0');
};

const createChannel = async (sendbird, channelUrl, channelName, userId) => {
  const groupChannelCreateParams = {
    isPublic: true,
    name: channelName,
    isSuper: false,
    isDistinct: false,
    isDiscoverable: true,
    isBroadcast: false,
    isEphemeral: false,
    channelUrl: channelUrl,
    invitedUserIds: [userId],
  };

  return sendbird.groupChannel.createChannel(groupChannelCreateParams);
};

const replaceUserTagsWithUsernames = (messageContent, usersTagged) => {
  let message = messageContent;
  usersTagged.forEach((tag) => {
    message = message.replaceAll(tag._id, tag.username);
  });
  return message.replaceAll(/<(\/?)usertag>/g, '');
};

const parseTimestamp = (timestamp, format = '') => {
  if (!timestamp) return;

  const date = timestamp.seconds ? new Date(timestamp.seconds * 1000) : timestamp;

  if (format === 'HH:mm') {
    return `${zeroPad(date.getHours(), 2)}:${zeroPad(date.getMinutes(), 2)}`;
  }

  return new Date(date).toLocaleDateString(i18n.locale, options.short);
};

const transformMessage = (message) => ({
  _id: message.messageId,
  content: message.message,
  senderId: message.messageType === 'admin' ? 'admin' : message.sender.userId,
  senderProfileUrl: message.messageType === 'admin' ? '' : message.sender.profileUrl,
  username: message.messageType === 'admin' ? 'הודעת מערכת' : message.sender.nickname,
  date: parseTimestamp(message.createdAt),
  timestamp: parseTimestamp(new Date(message.createdAt), 'HH:mm'),
  createdAt: new Date(message.createdAt),
  businessName: message.metaArrays.find(({ key }) => key === 'businessName')?.value[0],
  sendbirdMessage: message,
  initials: `${message.sender.nickname.split(' ')[0][0]}${
    message.sender.nickname.split(' ')[1] ? message.sender.nickname.split(' ')[1][0] : ''
  }`,
});

export default {
  components: { ChatWindow, CloseIcon, TrashCanIcon, Button },
  props: {
    channelName: { type: Object, required: true },
    channelUrl: { type: String, required: true },
    businessName: { type: String, required: true },
    isChannelEnabled: { type: Boolean, require: true },
  },
  setup(props, { emit }) {
    const { $i18n, $sendbird } = getCurrentInstance().proxy;
    const messages = ref([]);
    const rooms = ref([]);
    const channel = ref(null);
    const messagesLoaded = ref(false);
    const roomsLoaded = ref(false);
    const messagesListQuery = ref(null);
    const sendbirdConnected = ref(false);
    const textMessages = {
      ROOMS_EMPTY: $i18n.t('chat.textMessages.roomsEmpty'),
      ROOM_EMPTY: $i18n.t('chat.textMessages.roomEmpty'),
      NEW_MESSAGES: $i18n.t('chat.textMessages.newMessages'),
      MESSAGE_DELETED: $i18n.t('chat.textMessages.messageDeleted'),
      MESSAGES_EMPTY: $i18n.t('chat.textMessages.messagesEmpty'),
      CONVERSATION_STARTED: $i18n.t('chat.textMessages.conversationStarted'),
      TYPE_MESSAGE: $i18n.t('chat.textMessages.typeMessage'),
      SEARCH: $i18n.t('chat.textMessages.search'),
      IS_ONLINE: $i18n.t('chat.textMessages.isOnline'),
      LAST_SEEN: $i18n.t('chat.textMessages.lastSeen'),
      IS_TYPING: $i18n.t('chat.textMessages.isTyping'),
    };
    const { user, isAdmin } = useUser();
    const userId = computed(() => user.value?.id);
    const { sendbirdToken } = useSendbirdToken();
    const { channelUnreadMessagesCount, onChannelReadMessage } = useChannel();

    watch(
      [userId, sendbirdToken],
      async ([newUserId, newSendbirdToken]) => {
        if (newUserId && newSendbirdToken) {
          await $sendbird.connect(newUserId, newSendbirdToken);
          sendbirdConnected.value = true;
        }
      },
      { immediate: true }
    );

    const channelHandler = ref(null);
    const channelHandlerId = uuidv4();

    watch(
      [sendbirdConnected, channel, userId],
      async ([isSendbirdConnected, newChannel, newUserId]) => {
        if (isSendbirdConnected && newUserId) {
          $sendbird.groupChannel
            .getChannel(props.channelUrl)
            .then((groupChannel) => (channel.value = groupChannel))
            .catch(async (e) => {
              if (e.message === '"Channel" not found.') {
                channel.value = await createChannel($sendbird, props.channelUrl, props.channelName.name, newUserId);
              }
            });

          if (newChannel) {
            if (newChannel.members?.every((member) => member.userId !== newUserId) && props.isChannelEnabled)
              newChannel.value = await newChannel.join();

            rooms.value = [transformChannelToRoom(newChannel, props.channelName.subtitle)];
            roomsLoaded.value = true;

            const previousMessageListQueryParams = {
              includeMetaArray: true,
              limit: 30,
            };
            messagesListQuery.value = newChannel.createPreviousMessageListQuery(previousMessageListQueryParams);

            if (!channelHandler.value) {
              channelHandler.value = new GroupChannelHandler();
              channelHandler.value.onMessageReceived = async (channel, message) => {
                if (channel.url === props.channelUrl) {
                  messages.value = [...messages.value, transformMessage(message)];
                  await channel.markAsRead();
                } else {
                  emit('new-chat-message', message);
                }
              };
              channelHandler.value.onUnreadMemberStatusUpdated = function () {
                emit('messages-read');
              };
              channelHandler.value.onTypingStatusUpdated = function (channel) {
                const room = rooms.value[0];
                if (room.roomId !== channel.url) return;
                room.typingUsers = channel.getTypingUsers().map(({ userId }) => userId);
              };
              channelHandler.value.onMessageDeleted = function (channel, message) {
                if (newChannel.url !== channel.url) return;
                const index = messages.value.findIndex((msg) => msg._id === message);
                if (index !== -1) messages.value.splice(index, 1);
              };
              $sendbird.groupChannel.addGroupChannelHandler(channelHandlerId, channelHandler.value);
              await newChannel.markAsRead();
              emit('messages-read');
            }
          }
        }
      },
      { immediate: true }
    );

    const isUserTyping = ref(false);

    watch(isUserTyping, (newIsUserTyping) => {
      if (sendbirdConnected.value && channel.value) {
        newIsUserTyping ? channel.value.startTyping() : channel.value.endTyping();
      }
    });

    return {
      channelHandlerId,
      channel,
      messagesLoaded,
      textMessages,
      roomsLoaded,
      messages,
      rooms,
      userId,
      isAdmin,
      messagesListQuery,
      sendbirdConnected,
      isUserTyping,
      channelUnreadMessagesCount,
      onChannelReadMessage,
    };
  },
  computed: {
    typingUsers() {
      return getTypingText(this.rooms[0], this.userId, this.textMessages);
    },
  },
  async destroyed() {
    this.$emit('chat-close');
    if (this.channelHandlerId) {
      this.$sendbird.groupChannel.removeGroupChannelHandler(this.channelHandlerId);
    }

    if (this.$sendbird.connectionState === 'OPEN') {
      await this.$sendbird.disconnect();
    }
    this.sendbirdConnected = false;
  },
  methods: {
    shouldShowDelete(message, sendbirdCurrentUser) {
      return (
        this.isAdmin &&
        message.senderId === sendbirdCurrentUser.userId &&
        DateTime.fromJSDate(message.createdAt) > DateTime.local().minus({ hours: 1 })
      );
    },
    deleteMessage(message) {
      this.$confirm(
        this.$t('chat.deleteMessageConfirmation.message'),
        this.$t('chat.deleteMessageConfirmation.title'),
        {
          confirmButtonText: this.$t('chat.deleteMessageConfirmation.confirm'),
          confirmButtonClass: 'el-button--danger',
          cancelButtonText: this.$t('commons.cancel'),
          cancelButtonClass: 'el-button--secondary',
          showClose: false,
        }
      )
        .then(() => this.channel.deleteMessage(message.sendbirdMessage))
        .catch(() => null);
    },
    async sendMessage({ content, usersTag }) {
      const messageContentWithoutTags = usersTag.length ? replaceUserTagsWithUsernames(content, usersTag) : content;

      const sentMessage = {
        content: messageContentWithoutTags,
        senderId: this.$sendbird.currentUser.userId,
        senderProfileUrl: this.$sendbird.currentUser.profileUrl,
        username: this.$sendbird.currentUser.nickname,
        createdAt: new Date(),
        businessName: this.businessName,
        initials: `${this.$sendbird.currentUser.nickname.split(' ')[0][0]}${
          this.$sendbird.currentUser.nickname.split(' ')[1] ? this.$sendbird.currentUser.nickname.split(' ')[1][0] : ''
        }`,
      };

      const messageParams = {
        message: messageContentWithoutTags,
        metaArrays: [new MessageMetaArray({ key: 'businessName', value: [this.businessName] })],
      };

      this.channel.sendUserMessage(messageParams).onSucceeded((message) => {
        sentMessage.date = parseTimestamp(message.createdAt);
        sentMessage.timestamp = parseTimestamp(new Date(message.createdAt), 'HH:mm');
        sentMessage.sendbirdMessage = message;
        sentMessage._id = message.messageId;
        this.messages = [...this.messages, sentMessage];
        if (isNil(this.channelUnreadMessagesCount[message.channelUrl.split('_')[1]])) this.onChannelReadMessage();
      });
    },
    handleTyping() {
      this.isUserTyping = true;
      if (this.typingTimeoutId) clearTimeout(this.typingTimeoutId);
      this.typingTimeoutId = setTimeout(() => {
        this.isUserTyping = false;
      }, 1000);
    },
    loadMessages() {
      if (this.messagesListQuery && this.messagesListQuery.hasNext) {
        this.messagesListQuery.load().then((loadedMessages) => {
          this.messages = [...loadedMessages.map(transformMessage), ...this.messages];
          if (!this.messages.length) this.messagesLoaded = true;
        });
      }
      this.messagesLoaded = !this.messagesListQuery.hasNext;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@/stylesheets/scss/global';

::v-deep .vac-container-scroll {
  background: $white;
}

::v-deep .vac-offset-current {
  margin-left: 0;
  justify-content: normal;
}

::v-deep .vac-room-header {
  height: 5rem;
  align-items: start;
  padding-top: 12px;
}

.chat-container {
  width: 25rem;
  z-index: 10000; // All modal z-index begin at 2000 and is incremented by one each time a modal is open

  .actions-btn {
    cursor: pointer;
    &:hover {
      background: $secondary;
    }
    &.active {
      visibility: visible;
    }
  }

  .close-icon-container {
    height: fit-content;
  }

  .chat-card {
    max-width: 19.25rem;
  }
}

::v-deep .vac-message-wrapper .vac-message-box {
  max-width: 100%;
}

::v-deep .vac-box-footer .vac-textarea {
  border-radius: 3px;
  &::placeholder {
    font-size: $font-size-base;
  }
}

::v-deep .rtl-chat .vac-emoji-picker {
  left: 0.625rem;
  right: unset !important;
}

.badge-letters {
  font-size: 12px;
  font-weight: 500;
}

.badge-container {
  width: 1.5rem;
  height: 1.5rem;
}

.message-row {
  .delete-message-icon {
    visibility: hidden;
  }
  &:hover .delete-message-icon {
    visibility: visible;
  }
  .delete-message-icon:hover {
    color: $error !important;
  }
}

::v-deep .rtl-chat .vac-svg-button {
  transform: scaleX(-1) !important;
}
</style>
