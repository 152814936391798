var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(!_vm.channel),expression:"!channel"}],staticClass:"chat-container"},[_c('ChatWindow',{class:_vm.$direction === 'rtl' ? 'rtl-chat' : '',attrs:{"current-user-id":_vm.userId,"messages":_vm.messages,"messages-loaded":_vm.messagesLoaded,"text-messages":_vm.textMessages,"rooms-loaded":_vm.roomsLoaded,"loading-rooms":!_vm.roomsLoaded,"single-room":true,"rooms":_vm.rooms,"room-id":_vm.channelUrl,"show-new-messages-divider":false,"show-audio":false,"show-files":false,"show-add-room":false,"message-actions":[],"show-reaction-emojis":false,"show-footer":_vm.isChannelEnabled},on:{"send-message":_vm.sendMessage,"fetch-messages":_vm.loadMessages,"typing-message":_vm.handleTyping},scopedSlots:_vm._u([{key:"room-header",fn:function(){return [_c('div',{staticClass:"w-100 px-4 d-flex justify-content-between"},[_c('div',{staticClass:"w-100 text-truncate",class:_vm.$direction === 'ltr' ? 'me-2' : 'ms-2'},[_c('div',{staticClass:"w-100 text-truncate",class:_vm.$direction === 'ltr' ? 'me-2' : 'ms-2'},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"fw-bold d-inline-block text-truncate"},[_vm._v(" "+_vm._s(_vm.channelName.title)+" ")])]),_c('p',{staticClass:"d-inline-block text-truncate"},[_vm._v(_vm._s(_vm.channelName.subtitle))])]),(_vm.typingUsers)?_c('div',{staticClass:"vac-room-info vac-text-ellipsis"},[_vm._v(" "+_vm._s(_vm.typingUsers)+" ")]):_vm._e()]),_c('div',{staticClass:"actions-btn rounded close-icon-container",on:{"click":function($event){return _vm.$emit('chat-close')}}},[_c('CloseIcon')],1)])]},proxy:true},{key:"message",fn:function({ message }){return [(_vm.$sendbird.currentUser)?_c('div',{staticClass:"d-flex w-100 message-row",class:(message.senderId !== _vm.$sendbird.currentUser.userId) & (_vm.$direction === 'rtl') ||
          (message.senderId === _vm.$sendbird.currentUser.userId) & (_vm.$direction === 'ltr')
            ? 'flex-row-reverse'
            : 'flex-row'},[_c('div',{staticClass:"d-flex my-4",class:(message.senderId !== _vm.$sendbird.currentUser.userId) & (_vm.$direction === 'rtl') ||
            (message.senderId === _vm.$sendbird.currentUser.userId) & (_vm.$direction === 'ltr')
              ? 'flex-row-reverse'
              : 'flex-row'},[(message.senderProfileUrl)?[_c('img',{staticClass:"mx-2",attrs:{"src":message.senderProfileUrl,"alt":"","width":"30","height":"30"}})]:[_c('div',{staticClass:"badge-container d-flex justify-content-center align-items-center mx-2 bg-secondary rounded-pill"},[_c('h3',{staticClass:"text-uppercase badge-letters d-flex justify-content-center align-items-center mx-2 text-typography-secondary rounded-pill"},[_vm._v(" "+_vm._s(message.initials)+" ")])])],(_vm.$sendbird.currentUser)?_c('div',{class:[
              message.senderId === _vm.$sendbird.currentUser.userId
                ? 'bg-primary text-white'
                : 'bg-secondary text-typography-primary',
              'chat-card rounded p-2',
            ]},[_c('small',{staticClass:"fw-bold"},[_vm._v(" "+_vm._s(message.username)),(message.businessName)?[_vm._v(", "+_vm._s(message.businessName))]:_vm._e()],2),_c('p',{staticClass:"py-1"},[_vm._v(_vm._s(message.content))]),_c('small',{staticClass:"opacity-50"},[_vm._v(_vm._s(message.timestamp))])]):_vm._e()],2),(_vm.shouldShowDelete(message, _vm.$sendbird.currentUser))?_c('div',{staticClass:"m-2 delete-message-button"},[_c('Button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.deleteMessage(message)}}},[_c('div',{staticClass:"text-typography-secondary delete-message-icon"},[_c('TrashCanIcon')],1)])],1):_vm._e()]):_vm._e()]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }