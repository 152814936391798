import { useQuery, useResult } from '@vue/apollo-composable';
import { useNotification } from '@/modules/core';
import { SENDBIRD_TOKEN_QUERY, LIST_USER_CHANNELS_QUERY } from './queries';

export function useSendbirdToken() {
  const { error } = useNotification();

  const {
    result,
    loading: sendbirdTokenLoading,
    onError,
    refetch: sendbirdTokenRefetch,
  } = useQuery(SENDBIRD_TOKEN_QUERY);
  const sendbirdToken = useResult(result, null);

  onError((err) => {
    console.error('useSendbirdToken', err);
    error();
  });

  return {
    sendbirdToken,
    sendbirdTokenLoading,
    sendbirdTokenRefetch,
  };
}

export function useSendbirdChannels(channelUrls) {
  const { error } = useNotification();

  const { result, loading, onError, refetch } = useQuery(
    LIST_USER_CHANNELS_QUERY,
    () => ({ channelUrls: channelUrls.value }),
    () => ({ enabled: !!channelUrls.value?.length })
  );
  const channels = useResult(result, []);

  onError((err) => {
    console.error('useListUserChannels', err);
    error();
  });

  return {
    channels,
    loading,
    refetch,
  };
}
